html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
body,
#root {
  background-color: #fafafa;
  height: 100vh;
  padding: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: abbvoice;
  font-style: normal;
  font-weight: 400;
  src: url(./fonts/ABBvoice_W_Rg.eot);
  src: url(./fonts/ABBvoice_W_Rg.eot?#iefix) format('embedded-opentype'),
    url(./fonts/ABBvoice_W_Rg.woff) format('woff'),
    url(./fonts/ABBvoice_W_Rg.woff2) format('woff2');
}

@font-face {
  font-family: abbvoice;
  font-style: normal;
  font-weight: 500;
  src: url(./fonts/ABBvoice_W_Md.eot);
  src: url(./fonts/ABBvoice_W_Md.eot?#iefix) format('embedded-opentype'),
    url(./fonts/ABBvoice_W_Md.woff) format('woff'),
    url(./fonts/ABBvoice_W_Md.woff2) format('woff2');
}

@font-face {
  font-family: abbvoice;
  font-style: normal;
  font-weight: 700;
  src: url(./fonts/ABBvoice_W_Bd.eot);
  src: url(./fonts/ABBvoice_W_Bd.eot?#iefix) format('embedded-opentype'),
    url(./fonts/ABBvoice_W_Bd.woff) format('woff'),
    url(./fonts/ABBvoice_W_Bd.woff2) format('woff2');
}

.body {
  width: 100%;
  margin: 0;
  padding: 0;
  background: #f0f0f0;
  font-family: abbvoice, sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scrollStyle {
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0;
  }

  &::-webkit-scrollbar {
    height: 6px;
    background-color: #f0f0f0;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #6e6e6e;
  }
}

b {
  font-family: abbvoice, sans-serif;
  font-style: normal;
  font-weight: 300;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table {
  width: 100%;
  position: relative;
  table-layout: fixed;
  overflow-wrap: break-word;
  font-size: 12px;
  border-collapse: collapse;
  border-top: 1px solid #d2d2d2;
}

input::-ms-clear {
  display: none;
}

@media (max-width: 580px) {
  table {
    width: 640px;
  }
}

tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}

tr {
  border-bottom: 1px solid #d2d2d2;
  padding: 8px 0;
}

td,
th {
  display: table-cell;
  text-align: left;
  vertical-align: inherit;
  padding: 10px 0 10px 16px;
}

th {
  color: #6e6e6e;
  font-weight: 500;
}

td {
  color: #4a4a4a;
  font-weight: 400;
}

@keyframes pulse {
  0% {
    background-color: rgba(216, 216, 216, 0.1);
  }
  50% {
    background-color: rgba(216, 216, 216, 0.3);
  }
  100% {
    background-color: rgba(216, 216, 216, 0.1);
  }
}

.reportSettings{
  [hidden]{
    display:none

  }
  .Mui-disabled{
    border: none
  }
  .Mui-disabled.MuiAutocomplete-endAdornment, 
  .Mui-disabled.MuiListItemSecondaryAction-root,
  .Mui-disabled.MuiInputAdornment-positionEnd,
  .Mui-disabled.MuiFormHelperText-root,
  .Mui-disabled.MuiInputAdornment-positionEnd,
  .Mui-disabled.MuiButtonBase-root,
  .Mui-disabled.MuiChip-root > .MuiChip-deleteIcon{
    display:none
}

}